import get from 'lodash/get';
import { toast } from 'react-toastify';

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const catchErrors = (error) => {
    try {
        const errors = get(error, 'response.data.errors') || {};
        const common = get(error, 'response.data.message') || null;
        let message;
        Object.keys(errors).forEach((key) => {
            message = get(errors, `${key}.0`) || '';
        });
        console.log(message);
        toast.error(message || common || error.message);
    } catch (e) {
        toast.error('Something went wrong, try again later');
    }
};

export const numberFormat = (number) => {
    return Number(number).toFixed(4);
};
