import React from "react";
import { useLocation } from "react-router-dom";

import Navbar from "./Navbar.js";
import Sidebar from "./Sidebar.js";

const Main = ({ children }) => {
  const location = useLocation();
  if (location.pathname === "/auth/login") return children;
  return (
    <div className="flex flex-col h-screen">
      <div className="relative  ">
        <Navbar />
        {children}
      </div>
    </div>
  );
};
export default Main;
