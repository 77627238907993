import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Layout from "layouts/Main";

const routes = [
  // Auth Pages
  {
    path: "/auth/login",
    Component: lazy(() => import("pages/auth/login")),
    exact: true,
  },
  // Edit Profile
  {
    path: "/profile",
    Component: lazy(() => import("pages/account/profile")),
    exact: true,
  },
  // Change Password
  {
    path: "/changepassword",
    Component: lazy(() => import("pages/account/changepassword")),
    exact: true,
  },
  // Upload result
  {
    path: "/upload-result",
    Component: lazy(() => import("pages/upload")),
    exact: true,
  },
];

const WebRouter = () => {
  return (
    <Router>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state;
            return (
              <Switch location={location}>
                <Route
                  exact
                  path="/"
                  render={() => <Redirect to="/upload-result" />}
                />
                {routes.map(({ path, Component, exact }) => (
                  <Route
                    path={path}
                    key={path}
                    exact={exact}
                    render={() => {
                      return (
                        <div>
                          <Suspense fallback={null}>
                            <Component location={location} />
                          </Suspense>
                        </div>
                      );
                    }}
                  />
                ))}
                <Redirect to="/auth/404" />
              </Switch>
            );
          }}
        />
      </Layout>
    </Router>
  );
};

export default WebRouter;
