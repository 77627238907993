import axios from "axios";
import get from "lodash/get";

import store from "store";
import { logout } from "store/user";

const request = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  /// withCredentials: true,
  headers: {
    "content-type": "application/json",
  },
});
request.interceptors.request.use(
  (config) => {
    const {
      user: { token },
    } = store.getState();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    const status = get(error, "response.status");
    if (status === 401 || status === 403) {
      store.dispatch(logout());
      window.location = "/auth/login";
    }

    return Promise.reject(error);
  }
);

export default request;
