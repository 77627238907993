import { combineReducers, configureStore } from '@reduxjs/toolkit';

import user from './user';

const rootReducer = combineReducers({ user });

const reduxStore = () => {
    let store;

    const storage = require('redux-persist/lib/storage').default;
    const { persistReducer, persistStore } = require('redux-persist');

    const persistConfig = {
        key: 'root',
        whitelist: ['user'],
        storage
    };
    const persistedReducer = persistReducer(persistConfig, rootReducer);

    store = configureStore({ reducer: persistedReducer, middleware: [] });
    store.__persistor = persistStore(store);

    return store;
};

export default reduxStore();
