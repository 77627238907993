import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { SWRConfig } from "swr";

import store from "./store";
import request from "./libs/request";
import Router from "./router";
import "./styles/index.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";

const history = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={store.__persistor}>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          fetcher: request,
        }}
      >
        <Router history={history} />
        <ToastContainer />
      </SWRConfig>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
