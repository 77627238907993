import React, { useState, createRef, useCallback } from "react";
import Popper from "popper.js";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { logout } from "store/user";
import { catchErrors } from "libs/utils";

const UserDropdown = () => {
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const btnDropdownRef = createRef();
  const popoverDropdownRef = createRef();
  const dispatch = useDispatch();
  let history = useHistory();
  const openDropdownPopover = () => {
    new Popper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-end",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const onLogout = useCallback(async () => {
    try {
      dispatch(logout());
      history.push("/auth/login");
    } catch (error) {
      catchErrors(error);
    }
  }, []);

  return (
    <>
      <a
        className="text-gray-600 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full"></span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"
        }
        style={{ minWidth: "12rem" }}
      >
        <Link
          to="/profile"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          }
        >
          Edit Profile
        </Link>
        <Link
          to="/changepassword"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          }
        >
          Change password
        </Link>
        <div className="h-0 my-2 border border-solid border-gray-200" />
        <button
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          }
          onClick={onLogout}
        >
          Sign out
        </button>
      </div>
    </>
  );
};

export default UserDropdown;
