import React from "react";
import { Link, useLocation } from "react-router-dom";

import UserDropdown from "./UserDropdown.js";

const Navbar = () => {
  return (
    <>
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-no-wrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-no-wrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            <img src="/logo.png" width="120" height="120" alt="logo" />
          </Link>
          <div className="text-white text-sm uppercase hidden lg:inline-block font-semibold" />
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <UserDropdown />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
};
export default Navbar;
